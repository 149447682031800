<template>
  <div>
    <form-wizard
      :color="is_loading ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="is_loading ? 'Saving...' : 'Submit'"
      back-button-text="Previous"
      class="mb-3 setting-details"
      @on-complete="formSubmitted"
    >
      <!-- account details tab -->
      <tab-content
        title="Company Settings Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="company-form" class="p-0">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    rules="required"
                  >
                    <label class="required-l" for="company_name"
                      >Company Name</label
                    >
                    <b-form-input
                      id="company_name"
                      v-model="data_local.company_name"
                      name="company_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Company Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Email"
                    rules="required"
                  >
                    <label class="required-l" for="company_email"
                      >Company Email</label
                    >
                    <b-form-input
                      id="company_email"
                      v-model="data_local.company_email"
                      name="company_email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Company Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Phone"
                    rules="required"
                  >
                    <label class="required-l" for="company_phone"
                      >Company Phone</label
                    >
                    <b-form-input
                      id="company_phone"
                      v-model="data_local.company_phone"
                      name="company_phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Company Phone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Website"
                    rules="required"
                  >
                    <label class="required-l" for="company_website"
                      >Company Website</label
                    >
                    <b-form-input
                      id="company_website"
                      v-model="data_local.company_website"
                      name="company_website"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Company Website"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Company Address"
                  label-for="company_address"
                >
                  <b-form-textarea
                    id="company_address"
                    v-model="data_local.company_address"
                    name="company_address"
                    placeholder="Company Address"
                  />
                </b-form-group>
            <b-row class="uploadimg-row">
              <b-col sm="12" lg="6" class="py-1 my-50 border-right">
                <b-form-group label="Logo Light Theme" label-for="logo_for_light_theme">
                  <img v-if="data_local.light_logo" :src="data_local.light_logo" alt="Light Logo" />
                  <b-media-body class="">
                <div
                  v-show="imageSrc"
                  class="my-2 w-64 h-64 object-fill mx-auto position-relative"
                  style="
                    object-fit: fill;
                    width: 16rem;
                    height: 16rem;
                    margin-left: auto;
                    margin-right: auto;
                  "
                >
                  <img
                    style="display: block; max-width: 100%"
                    class="block max-w-full"
                    ref="img"
                    :src="imageSrc"
                  />
                  <button
                    class="btn btn-gray text-danger close-crop-btn"
                    @click.stop.prevent="fileCleared('image1')"
                  >
                    <feather-icon icon="XIcon" size="22" />
                  </button>
                </div>

                <div class="btn-crop-container mt-2">
                  <b-button
                    v-if="!imageSrc"
                    class="btn btn-blue w-32"
                    variant="primary"
                    pill
                    @click.stop.prevent="imageInput.click()"
                  >
                  Upload Photo
                  </b-button>
                  <b-button
                    v-if="imageSrc && !cropped"
                    class="btn btn-gray w-32"
                    variant="primary"
                    pill
                    @click.stop.prevent="handleCropImage('image1')"
                  >
                    Crop
                  </b-button>

                  <input
                    type="file"
                    ref="imageInput"
                    accept=".svg,.png"
                    @change="fileChanged('image1',$event)"
                    :style="{ display: 'none' }"
                  />

                  <b-button
                    class="btn btn-gray w-32"
                    variant="outline-danger"
                    pill
                    @click.stop.prevent="removeLogo('light_logo')"
                  >
                    Remove
                  </b-button>
                </div>
              </b-media-body>
                  <small class="text-secondary flex items-center d-block mt-50">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be 40x42 px and  less than 1MB and allowed formats are
                    <b>.svg, .png</b>
                  </small>
                  <!-- <b-img
                    v-if="data_local.company_logo"
                    ref="previewEl"
                    rounded
                    :src="data_local.company_logo"
                    height="80"
                  /> -->
                </b-form-group>
              </b-col>
              <b-col sm="12" lg="6" class="py-1 my-50">
                <b-form-group label="Logo Name Light Theme" label-for="logo_name_light_theme">
                <img v-if="data_local.light_name_logo" :src="data_local.light_name_logo" alt="Light Logo" />
                  <b-media-body class="">
                <div
                  v-show="imageSrc1"
                  class="my-2 w-64 h-64 object-fill mx-auto position-relative"
                  style="
                    object-fit: fill;
                    width: 16rem;
                    height: 16rem;
                    margin-left: auto;
                    margin-right: auto;
                  "
                >
                  <img
                    style="display: block; max-width: 100%"
                    class="block max-w-full"
                    ref="img1"
                    :src="imageSrc1"
                  />
                  <button
                    class="btn btn-gray text-danger close-crop-btn"
                    @click.stop.prevent="fileCleared('image2')"
                  >
                    <feather-icon icon="XIcon" size="22" />
                  </button>
                </div>

                <div class="btn-crop-container mt-2">
                  <b-button
                    v-if="!imageSrc1"
                    class="btn btn-blue w-32"
                    variant="primary"
                    pill
                    @click.stop.prevent="imageInput1.click()"
                  >
                  Upload Photo
                  </b-button>
                  <b-button
                    v-if="imageSrc1 && !cropped1"
                    class="btn btn-gray w-32"
                    variant="primary"
                    pill
                    @click.stop.prevent="handleCropImage('image2')"
                  >
                    Crop
                  </b-button>

                  <input
                    type="file"
                    ref="imageInput1"
                    accept=".svg,.png"
                    @change="fileChanged('image2',$event)"
                    :style="{ display: 'none' }"
                  />

                  <b-button
                    class="btn btn-gray w-32"
                    variant="outline-danger"
                    pill
                    @click.stop.prevent="removeLogo('light_name_logo')"
                  >
                    Remove
                  </b-button>
                </div>
              </b-media-body>
                  <small class="text-secondary flex items-center d-block mt-75">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be 96x55 px and less than 1MB and allowed formats are
                    <b>.svg, .png</b>
                  </small>
                  <!-- <b-img
                    v-if="data_local.company_logo"
                    ref="previewEl"
                    rounded
                    :src="data_local.company_logo"
                    height="80"
                  /> -->
                </b-form-group>
              </b-col>
              </b-row>
              <b-row class="uploadimg-row border-top">
              <b-col sm="12" lg="6" class="py-1 my-50 border-right">
                <b-form-group label="Logo Dark Theme" label-for="logo_for_dark_theme">
                  <img v-if="data_local.dark_logo" :src="data_local.dark_logo" alt="Dark Logo" />
                  <b-media-body class="">
                <div
                  v-show="imageSrc2"
                  class="my-2 w-64 h-64 object-fill mx-auto position-relative"
                  style="
                    object-fit: fill;
                    width: 16rem;
                    height: 16rem;
                    margin-left: auto;
                    margin-right: auto;
                  "
                >
                  <img
                    style="display: block; max-width: 100%"
                    class="block max-w-full"
                    ref="img2"
                    :src="imageSrc2"
                  />
                  <button
                    class="btn btn-gray text-danger close-crop-btn"
                    @click.stop.prevent="fileCleared('image3')"
                  >
                    <feather-icon icon="XIcon" size="22" />
                  </button>
                </div>

                <div class="btn-crop-container mt-2">
                  <b-button
                    v-if="!imageSrc2"
                    class="btn btn-blue w-32"
                    variant="primary"
                    pill
                    @click.stop.prevent="imageInput2.click()"
                  >
                    Upload Photo
                  </b-button>
                  <b-button
                    v-if="imageSrc2 && !cropped2"
                    class="btn btn-gray w-32"
                    variant="primary"
                    pill
                    @click.stop.prevent="handleCropImage('image3')"
                  >
                    Crop
                  </b-button>

                  <input
                    type="file"
                    ref="imageInput2"
                    accept=".svg,.png"
                    @change="fileChanged('image3',$event)"
                    :style="{ display: 'none' }"
                  />

                  <b-button
                    class="btn btn-gray w-32"
                    variant="outline-danger"
                    pill
                    @click.stop.prevent="removeLogo('dark_logo')"
                  >
                    Remove
                  </b-button>
                </div>
              </b-media-body>
                  <small class="text-secondary flex items-center d-block mt-75">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be 40x42 px and less than 1MB and allowed formats are
                    <b>.svg, .png</b>
                  </small>
                  <!-- <b-img
                    v-if="data_local.company_logo"
                    ref="previewEl"
                    rounded
                    :src="data_local.company_logo"
                    height="80"
                  /> -->
                </b-form-group>
              </b-col>
              <b-col sm="12" lg="6" class="py-1 my-50">
                <b-form-group label="Logo Name Dark Theme" label-for="logo_name_dask_theme">
                  <img v-if="data_local.dark_name_logo" :src="data_local.dark_name_logo" alt="Dark Logo" />
              <b-media-body class="">
                <div
                  v-show="imageSrc3"
                  class="my-2 w-64 h-64 object-fill mx-auto position-relative"
                  style="
                    object-fit: fill;
                    width: 16rem;
                    height: 16rem;
                    margin-left: auto;
                    margin-right: auto;
                  "
                >
                  <img
                    style="display: block; max-width: 100%"
                    class="block max-w-full"
                    ref="img3"
                    :src="imageSrc3"
                  />
                  <button
                    class="btn btn-gray text-danger close-crop-btn"
                    @click.stop.prevent="fileCleared('image4')"
                  >
                    <feather-icon icon="XIcon" size="22" />
                  </button>
                </div>

                <div class="btn-crop-container mt-2">
                  <b-button
                    v-if="!imageSrc3"
                    class="btn btn-blue w-32"
                    variant="primary"
                    pill
                    @click.stop.prevent="imageInput3.click()"
                  >
                    Upload Photo
                  </b-button>
                  <b-button
                    v-if="imageSrc3 && !cropped3"
                    class="btn btn-gray w-32"
                    variant="primary"
                    pill
                    @click.stop.prevent="handleCropImage('image4')"
                  >
                    Crop
                  </b-button>

                  <input
                    type="file"
                    ref="imageInput3"
                    accept=".svg,.png"
                    @change="fileChanged('image4',$event)"
                    :style="{ display: 'none' }"
                  />

                  <b-button
                    class="btn btn-gray w-32"
                    variant="outline-danger"
                    pill
                    @click.stop.prevent="removeLogo('dark_name_logo')"
                  >
                    Remove
                  </b-button>
                </div>
              </b-media-body>
                  <small class="text-secondary flex items-center d-block mt-50">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be 96x55 px and  less than 1MB and allowed formats are
                    <b>.svg, .png</b>
                  </small>
                  <!-- <b-img
                    v-if="data_local.company_logo"
                    ref="previewEl"
                    rounded
                    :src="data_local.company_logo"
                    height="80"
                  /> -->
                </b-form-group>
              </b-col>
            </b-row>
              </b-col>
              <b-col md="6">
                <b-form-group label="Company Logo" label-for="company_logo">
                  <file-pond
                    ref="pond"
                    name="company_logo"
                    max-file-size="1MB"
                    max-files="1"
                    accepted-file-types="image/jpeg, image/png"
                    allow-multiple="false"
                    @addfile="fileSelected"
                    @removefile="fileSelected"
                    v-on:init="handleFilePondInit"
                  />
                  <small class="text-secondary flex items-center d-block mt-50">
                    <feather-icon icon="AlertCircleIcon" class="mr-25"/>
                    File size should be less than 1MB and allowed formats are
                    <b>.jpeg, .png</b>
                  </small>
                  <b-img
                    v-if="data_local.company_logo"
                    ref="previewEl"
                    rounded
                    :src="data_local.company_logo"
                    height="80"
                    class="mt-1"
                  />
                </b-form-group>
                
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BImg,
  BCard,
  BMedia,
  BMediaAside,
  BLink,
} from "bootstrap-vue";
import { required, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { 
  ref,
  watchEffect,
  onMounted,
  onUnmounted,
  watch, } from "@vue/composition-api";
import store from "@/store";
import settingsStoreModule from "../settingsStoreModule";
import bomb from "@/libs/bomb/bomb";
import Cropper from "cropperjs";
// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    FilePond,
    BImg,
    BMedia,
    BMediaAside,
    // BMediaBody,
    BLink,
  },
  data() {
    return {
      required,
      numeric,
      is_loading: false,
      myFiles: [],
      data_local: {
        company_name: "",
        company_email: "",
        company_phone: "",
        company_website: "",
        company_address: "",
        company_logo_url: "",
        company_logo: "",
        light_logo:"",
        light_name_logo:"",
        dark_logo:"",
        dark_name_logo:""
      },
    
    };
  },
  setup() {

   
    const imageInput = ref(null);
    const imageInput1 = ref(null);
    const imageInput2 = ref(null);
    const imageInput3 = ref(null);

    const selectedFile = ref(null);
    const selectedFile1 = ref(null);
    const selectedFile2 = ref(null);
    const selectedFile3 = ref(null);

     const imageSrc = ref(null);
      const imageSrc1 =  ref(null);
      const imageSrc2 =  ref(null);
      const imageSrc3 = ref(null);


      let cropper = null;
      let cropper1 =   null;
      let cropper2 =   null;
      let cropper3 =   null;


     const cropped = ref(false);
      const cropped1 =   ref(false);
      const cropped2 =   ref(false);
      const cropped3 =   ref(false);

      const img = ref(null);
      const img1 = ref(null);
      const img2 = ref(null);
      const img3 = ref(null);


      

    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      imageSrc.value = event.target.result;
      imageSrc1.value = event.target.result;
      imageSrc2.value = event.target.result;
      imageSrc3.value = event.target.result;

    };


const handleCropImage = (image) => {
  if (image === "image1" && cropper) {
    if (cropper.getCroppedCanvas) {
    console.log(selectedFile.value )

      cropper.getCroppedCanvas({
        width: 40,
        height: 42,
        fillColor:"#fff",
        imageSmoothingQuality: 'high',
      }).toBlob((blob) => {
        selectedFile.value = blob;
        cropped.value = true;
      }, "image/jpeg",{ transparency: true });
    } else {
      console.error("getCroppedCanvas method not available in cropper");
    }
  } else if (image === "image2" && cropper1) {

    if (cropper1.getCroppedCanvas) {
      cropper1.getCroppedCanvas({
        width: 96,
        height: 55,
        fillColor:"#fff",
        imageSmoothingQuality: 'high',
      }).toBlob((blob) => {
        selectedFile1.value = blob;
        cropped1.value = true;
      }, "image/jpeg");

    console.log(selectedFile1.value )

    } else {
      console.error("getCroppedCanvas method not available in cropper1");
    }
  } else if (image === "image3" && cropper2) {
    if (cropper2.getCroppedCanvas) {
      cropper2.getCroppedCanvas({
        width: 40,
        height: 42,
        fillColor:"#191C24",
        imageSmoothingQuality: 'high',
      }).toBlob((blob) => {
        selectedFile2.value = blob;
        cropped2.value = true;
      }, "image/jpeg");
    console.log(selectedFile2.value )

    } else {
      console.error("getCroppedCanvas method not available in cropper2");
    }
  } else if (image === "image4" && cropper3) {
    if (cropper3.getCroppedCanvas) {
      cropper3.getCroppedCanvas({
        width: 96,
        height: 55,
        fillColor:"#191C24",
        imageSmoothingQuality: 'high',
      }).toBlob((blob) => {
        // selectedFile3.value = blob;
        // cropped3.value = true;
        selectedFile3.value = blob;
        cropped3.value = true;
      }, "image/jpeg");
    console.log(selectedFile3.value )

    } else {
      console.error("getCroppedCanvas method not available in cropper3");
    }
  } else {
    console.error("Invalid image or cropper instance:", image, cropper, cropper1, cropper2, cropper3);
  }
};


    const fileChanged = (inputKey, e) => {
    const files = e.target.files || e.dataTransfer.files;
    if (files.length) {
      switch (inputKey) {
        case 'image1':
        selectedFile.value = files[0];
        break;
        case 'image2':
        selectedFile1.value = files[0];
        break;
        case 'image3':
        selectedFile2.value = files[0];
        break;
        case 'image4':
        selectedFile3.value = files[0];
        break;
        default:
        break;
      }
    }
  }

  const fileCleared = (fileNumber) => {
     console.log(fileNumber)
      switch(fileNumber) {
        case "image1":
          console.log('hwwd')
          selectedFile.value = null;
          cropped.value = false;
          break
        case "image2":
          selectedFile1.value = null
          cropped1.value = false
          break
        case "image3":
          selectedFile2.value = null
          cropped2.value = false
          break
        case "image4":
          selectedFile3.value = null
          cropped3.value = false
          break
        default:
          selectedFile.value = null
          cropped.value = false
          break
      }
    }

    const SETTING_APP_STORE_MODULE_NAME = "app-setting";

    // Register module
    if (!store.hasModule(SETTING_APP_STORE_MODULE_NAME))
      store.registerModule(SETTING_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onMounted(() => {
      cropper = new Cropper(img.value, {
        aspectRatio: 1,
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        dragMode: "move",
        background: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        viewMode: 1, // Ensure the image cannot be moved outside the cropping box
        autoCropArea: 1, // Ensure the crop box covers the entire image initially
      });
      cropper1 = new Cropper(img1.value, {
        aspectRatio: 96/55,
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        dragMode: "move",
        background: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        viewMode: 1, // Ensure the image cannot be moved outside the cropping box
        autoCropArea: 1, // Ensure the crop box covers the entire image initially
 
      }); 
      cropper2 = new Cropper(img2.value, {
        aspectRatio: 1,
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        dragMode: "move",
        background: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        viewMode: 1, // Ensure the image cannot be moved outside the cropping box
        autoCropArea: 1, // Ensure the crop box covers the entire image initially
      }); 
      cropper3 = new Cropper(img3.value, {
        aspectRatio: 96/55,
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        dragMode: "move",
        background: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        viewMode: 1, // Ensure the image cannot be moved outside the cropping box
        autoCropArea: 1, // Ensure the crop box covers the entire image initially
      });
    });
    onUnmounted(() => {
      cropper.destroy();
      cropper1.destroy();
      cropper2.destroy();
      cropper3.destroy();

      if (store.hasModule(SETTING_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_APP_STORE_MODULE_NAME);
    });
    watchEffect(() => {
  if (selectedFile.value) {
    const fileReader1 = new FileReader();
    fileReader1.onload = (event) => {
      imageSrc.value = event.target.result;
    };
    fileReader1.readAsDataURL(selectedFile.value);
  } else {
    imageSrc.value = null;
  }
});

watchEffect(() => {
  if (selectedFile1.value) {
    const fileReader2 = new FileReader();
    fileReader2.onload = (event) => {
      imageSrc1.value = event.target.result;
    };
    fileReader2.readAsDataURL(selectedFile1.value);
  } else {
    imageSrc1.value = null;
  }
});

watchEffect(() => {
  if (selectedFile2.value) {
    const fileReader3 = new FileReader();
    fileReader3.onload = (event) => {
      imageSrc2.value = event.target.result;
    };
    fileReader3.readAsDataURL(selectedFile2.value);
  } else {
    imageSrc2.value = null;
  }
});

watchEffect(() => {
  if (selectedFile3.value) {
    const fileReader4 = new FileReader();
    fileReader4.onload = (event) => {
      imageSrc3.value = event.target.result;
    };
    fileReader4.readAsDataURL(selectedFile3.value);
  } else {
    imageSrc3.value = null;
  }
});

// Repeat similar code blocks for selectedFile2 and selectedFile3


    watch(
      imageSrc,
      () => {
        if (imageSrc.value) {
          cropper.replace(imageSrc.value);
        }
      },
      {
        flush: "post",
      }
    );
    watch(
      imageSrc1,
      () => {
        if (imageSrc1.value) {
          cropper1.replace(imageSrc1.value);
        }
      },
      {
        flush: "post",
      }
    );
    watch(
      imageSrc2,
      () => {
        if (imageSrc2.value) {
          cropper2.replace(imageSrc2.value);
        }
      },
      {
        flush: "post",
      }
    );
    watch(
      imageSrc3,
      () => {
        if (imageSrc3.value) {
          cropper3.replace(imageSrc3.value);
        }
      },
      {
        flush: "post",
      }
    );
    return {
      imageInput,
      imageInput1,
      imageInput2,
      imageInput3,
      selectedFile,
      selectedFile1,
      selectedFile2,
      selectedFile3,
      fileChanged,
      fileCleared,
      imageSrc,
      imageSrc1,
      imageSrc2,
      imageSrc3,
      img,
      img1,
      img2,
      img3,
      handleCropImage,
      cropped,
      cropped1,
      cropped2,
      cropped3
    };
  },
  created() {
    this.fetch_setting_data();
  },
  methods: {
       toastMsg(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: "BellIcon",
          variant: "danger",
          // text: res.data.message,
        },
      });
    },

    removeLogo(imageKey) {
    const data = {
      params: {
        [`${imageKey}`]: true, // Dynamic key for removal
      },
    };
    this.$store
        .dispatch("app-setting/updateSettings", {
          data: data,
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Company Settings Updated",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
            // this.$router.push("/settings");
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Company updating Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Company updating Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
  },
    handleFilePondInit() {},
    fetch_setting_data() {
      this.$store.dispatch("app-setting/fetchSettings").then((res) => {
        var company_settings = res.data.data.settings;
        this.data_local = company_settings;
      });
    },
    // removeLogo() {
    //   this.data_local.company_logo_url = "";
    // },
  
     async getImageSize(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = e.target.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  },
 async formSubmitted() {
    const self = this;
    const formData = new FormData(document.getElementById("company-form"));
    formData.delete("files[]"); // data is the name of the original input file; import to delete

      const files = self.$refs.pond.getFiles();

      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append("company_logo", files[i].file);
      
      }
      if(this.selectedFile != null){
      formData.append("light_logo", this.selectedFile);
      }
      if(this.selectedFile1 != null){
      formData.append("light_name_logo", this.selectedFile1);
      }
      if(this.selectedFile2 != null){
      formData.append("dark_logo", this.selectedFile2);
      }
      if(this.selectedFile3 != null){
      formData.append("dark_name_logo", this.selectedFile3);
      }

       this.$store
        .dispatch("app-setting/updateSettings", {
          data: formData,
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Company Settings Updated",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
            // this.$router.push("/settings");
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Company updating Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Company updating Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });

//       const self = this;

// const formData = new FormData(document.getElementById("company-form"));
// formData.delete("files[]"); // data is the name of the original input file; import to delete

// const files = self.$refs.pond.getFiles();
// const keys = Object.keys(self.$refs.pond.getFiles());
// for (let i = 0; i < keys.length; i += 1) {
//   formData.append("company_logo", files[i].file);
// }

// this.$store
//   .dispatch("app-setting/updateSettings", {
//     data: formData,
//   })
//   .then((res) => {
//     if (res.data.success) {
//       this.$toast({
//         component: ToastificationContent,
//         props: {
//           title: "Company Settings Updated",
//           icon: "BellIcon",
//           variant: "success",
//           text: res.data.message,
//         },
//       });
//       // this.$router.push("/settings");
//     } else {
//       this.$toast({
//         component: ToastificationContent,
//         props: {
//           title: "Oops! Company updating Failed",
//           icon: "BellIcon",
//           variant: "danger",
//           text: res.data.message,
//         },
//       });
//     }
//   })
//   .catch((error) => {
//     this.$toast({
//       component: ToastificationContent,
//       props: {
//         title: "Oops! Company updating Failed",
//         icon: "BellIcon",
//         variant: "danger",
//         text: error,
//       },
//     });
//   });
    },

    validationForm() {
      const self = this;
      if (
        self.data_local.company_logo === "" &&
        this.$refs.pond.getFiles().length === 0
      ) {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Logo Needed",
            icon: "BellIcon",
            variant: "danger",
            text: "Kindly upload a logo",
          },
        });
        return false;
      }
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fileSelected(e) {
      
      this.isFileSelected  = !this.isFileSelected;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "~cropperjs/dist/cropper.css";
.filepond--credits {
  display: none;
}
.cropper-crop-box, .cropper-view-box {
    border-radius: 0% !important;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

.filepond--credits {
  display: none;
}
.close-crop-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.btn-crop-container {
  display: flex;
  justify-content: center;
  align-content: flex-end;
}


</style>

